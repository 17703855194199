import React, { useEffect, useState, useRef } from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Container,
	Paper,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Fab,
	Box,
	CardContent,
	CardActions,
	Card,
	Modal,
	useMediaQuery,
	Menu,
	IconButton,
	CircularProgress,
	Backdrop,
	SwipeableDrawer,
	ListItem,
	ListItemText,
	Divider, Chip
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { useAuthState } from "react-firebase-hooks/auth";
// import { auth } from '../firebase';
import { getAuth, signInAnonymously } from "firebase/auth"; // Add this import
// import { app } from '../Firebase'; // Import the app instance
import { auth, db } from "../Firebase";
import {
	collection,
	addDoc,
	Timestamp,
	doc,
	updateDoc, query, where, getDocs 
} from "firebase/firestore";

import Chat from "../Components/Chat";
import heroImage from "../Assets/heroImage.png";
import StarIcon from "@mui/icons-material/Star";
import BonusIcon from "../Assets/bonus.png";
import SpaceialOffer from "../Assets/special-offer.png";
import AddInfo from "./AddInfo";
import EditChatbot from "./EditChatbot";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AppBarMenu from "../Components/AppBarMenu";

import { getFbUser } from "../Helpers/getFbUser";
import { ChatThread, FbUser } from "../Helpers/Interface";
import { onAuthStateChanged } from "firebase/auth";
import useAppBarHeight from "../Helpers/useAppBarHeight";
import useCustomerStatus from "../Helpers/useCustomerStatus";
import UpdateView from "../Components/UpdateView";
import useCustomerMustUpdate from "../Helpers/useCustomerMustUpdate";

export default function ChatView() {
	/**
	 * - Check if user is logged in
	 * - If not signing anonymously
	 * - Show user to signup to save their chat
	 * - User must signup to continue using the chat
	 * - Get user chatbot info
	 * - If chatbot is not setup then setup the bot
	 */
	const [user] = useAuthState(auth);
	const [fbUser, setFbUser] = useState<FbUser | undefined>();
	const [relation, setRelation] = useState<
		| "father"
		| "mother"
		| "brother"
		| "sister"
		| "friend"
		| "aunt"
		| "uncle"
		| "cousin"
		| "other"
		| string
	>("mother");
	const [name, setName] = useState<string>("");
	const [userLanguage, setUserLanguage] = useState<string>("English");
	const [refresh, setRefresh] = useState<boolean>(false);

	const [chats, setChats] = useState<ChatThread[]>([]);
	const [selectedChat, setSelectedChat] = useState<ChatThread | undefined>();

	const appBarHeight = useAppBarHeight();

	const [showPreLaunch, setShowPreLaunch] = useState<boolean>(false);

	const nameInputRef = useRef<HTMLInputElement>(null); // Create a ref for the input

	const [openEditChatbotModal, setOpenEditChatbotModal] =
		useState<boolean>(false);

	const [openBackdrop, setOpenBackdrop] = useState(false);

	const [openChats, setOpenChats] = useState<boolean>(false);

	const isLargeScreen = useMediaQuery("(min-width:800px)");
	const navigate = useNavigate();

	const userIsPremium = useCustomerStatus()
	const userMustUpdate = useCustomerMustUpdate() //TODO: prevent user from using the app if they must update

	const [openUpdate, setOpenUpdate] = useState<boolean>(!userIsPremium || userMustUpdate)


	const CssTextField = styled(TextField)({
		"& label.Mui-focused": {
			color: "#A0AAB4",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#B2BAC2",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#E0E3E7",
			},
			"&:hover fieldset": {
				borderColor: "#B2BAC2",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#6F7E8C",
			},
		},
	});

	useEffect(() => {
		console.log("user status is: ", user?.uid);
		setOpenBackdrop(true);

		onAuthStateChanged(auth, (user) => {
			if (!user) {
				console.log("user is not signed in");
				setOpenBackdrop(false);
				navigate("/signin");
				return;
			}

			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/firebase.User

			console.log("user is signed in");		

			getChats(user.uid).then((chats) => {
				chats && setChats(chats);
			});

			if (fbUser) {
				console.log("fbUser already exists. intro: ", fbUser.intro);
				setOpenBackdrop(false);

				if (fbUser.intro == false){
					setOpenEditChatbotModal(true);
					setOpenUpdate(true)
				}
				return;
			}

			const uid = user.uid;

			getFbUser(user.uid)
				.then((dbFbUser) => {
					if(!dbFbUser) return;

					console.log("dbFbUser intro: ", dbFbUser.intro);
					console.log("fbUser: ", fbUser);

					if (dbFbUser.intro == false) {
						setOpenEditChatbotModal(true);
						setOpenUpdate(true)
						
						saveIntroShown(dbFbUser.userId)
						
						setFbUser({ ...dbFbUser, intro: true })
					}else{
						setFbUser(dbFbUser);
					}

					setOpenBackdrop(false);
				})
				.catch((error) => {
					console.log("error getting fbuser", error);
					setOpenBackdrop(false);
				});

			console.log("fbUser", fbUser);
			// ...
		});
	}, []);

	useEffect(() => {
		console.log("fbUser", fbUser);
	}, [fbUser]);

	useEffect(() => {
		if (nameInputRef.current) {
			console.log("focusing in ref");
			nameInputRef.current.focus(); // Maintain focus on the input
		}
	}, [name]);

	const handleSignIn = async () => {
		const authInstance = getAuth(); // Get the auth instance

		await signInAnonymously(authInstance); // Use the imported method
	};

	async function saveIntroShown(userId: string) {
		console.log("saving intro shown...");
		const userRef = doc(db, "users", userId);

		try {
			await updateDoc(userRef, {
				intro: true,
			});
			console.log("intro shown saved!");
			return true
		} catch (error) {
			console.log("error saving intro shown", error);
			return false
		}
		
	}

	function handleRelationChange(event: SelectChangeEvent) {
		setRelation(event.target.value as string);
		console.log("relation", event.target.value);
	}

	function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		setName(event.target.value);

		if (nameInputRef.current) {
			console.log("focusing");
			nameInputRef.current.focus(); // Maintain focus on the input
		}
	}

	function handleLanguageChange(event: SelectChangeEvent) {
		setUserLanguage(event.target.value as "Turkish" | "English");
		// console.log("language", event.target.value);
	}

	function LanguageSelector() {
		return (
			<FormControl fullWidth>
				<InputLabel id="language-select-label">Language</InputLabel>
				<Select
					labelId="language"
					id="language"
					value={userLanguage}
					label="Language"
					onChange={handleLanguageChange}
				>
					<MenuItem value={"Turkish"}>Turkish</MenuItem>
					<MenuItem value={"English"}>English</MenuItem>
				</Select>
			</FormControl>
		);
	}

	function handleRefresh() {
		console.log("refreshing");
		setRefresh(true);
		setTimeout(() => {
			setRefresh(false);
		}, 500);
	}

	function RefreshButton() {
		return (
			<Button
				variant="outlined"
				onClick={handleRefresh}
				style={{ marginBottom: "1rem", color: "white", borderColor: "white" }}
			>
				Refresh
			</Button>
		);
	}

	const Iframe: React.FC<{ src: string; height: string; width: string }> = ({
		src,
		height,
		width,
	}) => {
		return (
			<div>
				<iframe src={src} height={height} width={width} />
			</div>
		);
	};

	function PreLaunchBuy() {
		return (
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				style={{ textAlign: "center", marginTop: "20px" }}
			>
				<Grid>
					{/* <Card>
              <CardContent>  */}
					<Typography variant="h4" gutterBottom style={{ color: "#fd79a8" }}>
						Grab the Pre-Launch Special
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						style={{ fontSize: "1.2rem", color: "white" }}
					>
						Don't miss out on the pre-launch special
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						style={{ fontSize: "1.2rem", color: "white" }}
					>
						Get the "Life Time" deal that covers 2 Mini Journals for 2 children
					</Typography>
					<Grid
						container
						spacing={5}
						justifyContent="center"
						style={{ marginTop: "20px" }}
					>
						<Grid size={{ xs: 12, md: 6 }}>
							<Card
								style={{
									borderRadius: "16px",
									position: "relative",
									overflow: "visible",
								}}
							>
								<img
									src={SpaceialOffer}
									alt="Special Offer"
									style={{
										position: "absolute",
										top: window.innerWidth > 960 ? "-40px" : "-10px",
										left: window.innerWidth > 960 ? "-60px" : "0px",
										width: window.innerWidth > 960 ? "100px" : "80px",
										height: "auto",
									}}
								/>
								<CardContent>
									<Grid container>
										<Grid
											size={12}
											justifyContent="center"
											style={{ textAlign: "center" }}
										>
											<Typography
												variant="h4"
												gutterBottom
												style={{ color: "#fdcb6e" }}
											>
												Pre Launch
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.5rem", color: "white" }}
											>
												$99
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												Lifetime
											</Typography>
											<img
												src={BonusIcon}
												alt="Bonus Icon"
												style={{
													width: "100px",
													height: "auto",
													borderRadius: "20px",
												}}
											/>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												2 Mini Journal
												<br />
												for
												<br />2 Children
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<CardActions>
									{" "}
									<Grid container justifyContent="center">
										<Grid size={10}>
											<Button
												variant="outlined"
												style={{
													color: "white",
													borderColor: "#FC5A5A",
													backgroundColor: "#FC5A5A",
													width: "100%",
												}}
												onClick={() =>
													window.open(
														"https://buy.stripe.com/6oE8y3aI3aRufNm144",
														"_blank",
														"width=1000,height=900"
													)
												}
											>
												Buy Now!
											</Button>
										</Grid>
									</Grid>
								</CardActions>
							</Card>
						</Grid>

						{/* After Launch */}
						<Grid size={{ xs: 12, md: 6 }}>
							<Card sx={{ height: "100%" }} style={{ borderRadius: "16px" }}>
								<CardContent>
									<Grid container>
										<Grid
											size={12}
											justifyContent="center"
											style={{ textAlign: "center" }}
										>
											<Typography
												variant="h4"
												gutterBottom
												style={{ color: "#fdcb6e" }}
											>
												After Launch
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.5rem", color: "white" }}
											>
												$99
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												Per Year
											</Typography>
											{/* <img src={BonusIcon} alt="Bonus Icon" style={{ width: '100px', height: 'auto', marginBottom: '20px' , borderRadius: '20px'}} /> */}

											<div style={{ height: "100px", width: "100px" }}></div>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												1 Mini Journal
												<br />
												for
												<br />1 Child
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<CardActions>
									{" "}
									<Grid container justifyContent="center">
										<Grid size={10}>
											<Button
												variant="outlined"
												style={{
													color: "#FC5A5A",
													borderColor: "#FC5A5A",
													width: "100%",
												}}
												onClick={() =>
													window.open(
														"https://forms.gle/bw8yR5N25ypowcdj7",
														"_blank",
														"width=1000,height=900"
													)
												}
											>
												Notify Me
											</Button>
										</Grid>
									</Grid>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
					{/* </CardContent>
              </Card> */}

					{/* </Card> */}
				</Grid>
			</Grid>
		);
	}

	function FloatingPreLaunchButton() {
		return (
			<Box
				sx={{ "& > :not(style)": { m: 1 } }}
				style={{
					margin: 0,
					top: "auto",
					right: 20,
					bottom: 20,
					left: "auto",
					position: "fixed",
					zIndex: 1000,
				}}
			>
				<Fab
					variant="extended"
					style={{ backgroundColor: "#FC5A5A", color: "white" }}
					onClick={() =>
						window.open(
							"https://buy.stripe.com/6oE8y3aI3aRufNm144",
							"_blank",
							"width=1000,height=900"
						)
					}
				>
					<StarIcon sx={{ mr: 1 }} />
					Get Pre-Launch Special
				</Fab>
			</Box>
		);
	}

	function FloatingNotifyButton() {
		return (
			<Box
				sx={{ "& > :not(style)": { m: 1 } }}
				style={{
					margin: 0,
					top: "auto",
					right: 20,
					bottom: 20,
					left: "auto",
					position: "fixed",
					zIndex: 1000,
				}}
			>
				<Fab
					variant="extended"
					style={{ backgroundColor: "#FC5A5A", color: "white" }}
					onClick={() =>
						window.open(
							"https://forms.gle/bw8yR5N25ypowcdj7",
							"_blank",
							"width=1000,height=900"
						)
					}
				>
					<StarIcon sx={{ mr: 1 }} />
					Notify Me!
				</Fab>
			</Box>
		);
	}

	function ChatBotEditModal() {
		const [open, setOpen] = useState(false);
		const handleOpen = () => setOpen(true);
		const handleClose = () => setOpen(false);

		return (
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<Grid
						container
						style={{
							marginBottom: "20px",
							marginTop: "20px",
							justifyContent: "space-between",
							alignContent: "center",
						}}
						spacing={3}
					>
						<AddInfo name={name} setName={setName} />

						<Grid size={{ xs: 12, md: 3 }}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">
									Your Relation
								</InputLabel>
								<Select
									labelId="relation"
									id="relation"
									value={relation}
									label="Your Relation"
									onChange={handleRelationChange}
									defaultValue={"mother"}
								>
									<MenuItem value={"father"}>Father</MenuItem>
									<MenuItem value={"mother"}>Mother</MenuItem>
									{/* <MenuItem value={30}>Thirty</MenuItem> */}
								</Select>
							</FormControl>
						</Grid>
						<Grid size={{ xs: 12, md: 3 }}>
							<LanguageSelector />
						</Grid>
						<Grid
							size={{ xs: 12, md: 3 }}
							style={{ display: "flex", alignItems: "center" }}
						>
							<RefreshButton />
						</Grid>
					</Grid>
				</Box>
			</Modal>
		);
	}

	// function useAppBarHeight(): number {
	// 	type MinHeight = {
	// 		minHeight: number;
	// 	};

	// 	const {
	// 		mixins: { toolbar },
	// 		breakpoints,
	// 	} = useTheme();
	// 	const toolbarDesktopQuery = breakpoints.up("sm");
	// 	const toolbarLandscapeQuery = `${breakpoints.up("xs")} and (orientation: landscape)`;
	// 	const isDesktop = useMediaQuery(toolbarDesktopQuery);
	// 	const isLandscape = useMediaQuery(toolbarLandscapeQuery);
	// 	let currentToolbarMinHeight;
	// 	if (isDesktop) {
	// 		currentToolbarMinHeight = toolbar[toolbarDesktopQuery];
	// 	} else if (isLandscape) {
	// 		currentToolbarMinHeight = toolbar[toolbarLandscapeQuery];
	// 	} else {
	// 		currentToolbarMinHeight = toolbar;
	// 	}
	// 	return (currentToolbarMinHeight as MinHeight).minHeight;
	// }

	function EditChatbotModal() {
		// const [open, setOpen] = useState(false);
		// const handleOpen = () => setOpen(true);
		const handleClose = () => setOpenEditChatbotModal(false);

		return (
			<Modal
				open={openEditChatbotModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				// style={{display: 'flex', alignItems: 'center', flexGrow: 1}}
			>
				<EditChatbot
					user={fbUser}
					setUser={setFbUser}
					closeModal={handleClose}
				/>
			</Modal>
		);
	}

	function handleOpenEditChatbotModal() {
		console.log("opening edit chatbot modal");
		console.log("fbuser: ", fbUser);
		setOpenEditChatbotModal(true);
	}

	function MyBackdrop() {
		return (
			<Backdrop
				sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
				open={openBackdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}

	function handleChatClick(chatId:string){
		console.log("chat clicked", chatId);

		const selectedChat = chats.find((chat) => chat.threadId === chatId);
		if (selectedChat) {
			setSelectedChat(selectedChat);
		}

		setOpenChats(false);
	}

	function ChatCell({chat}:{chat:ChatThread}){
		
			return (
				<>
				 <ListItem onClick={() => handleChatClick(chat.threadId)} id={chat.threadId}>
             {/* <ListItemAvatar>
          <Avatar alt="Memory" src={src} />
        </ListItemAvatar> */}
            <ListItemText style={{color:"#656E71"}} primary={chat.created.toDate().toLocaleDateString()} 
	
            secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: 'white', display: 'inline' }}

                  >
                    {chat.messages[0].content.substring(0, 50)}
                  </Typography>
                  
                </>
            }
            />
            
        </ListItem>
		<Divider />
		</>
		)

	}

	async function getChats(userId:string){
		const chatRef = collection(db, "chats");
		const chats = query(chatRef, where("userId", "==", userId));

		try {
			const chatsSnapshot = await getDocs(chats);
			return chatsSnapshot.docs.map((doc) => doc.data() as ChatThread).sort((a, b) => (b.created.seconds) - (a.created.seconds));
		} catch (error) {
			console.log("error getting chats", error);
		}
	}

	return (
		<Grid
			container
			style={{
				height: "100svh",
				margin: 0,
				width: "100svw",
				maxWidth: "100vw",
				overflowX: "hidden",
			}}
		>
			<MyBackdrop />
			<UpdateView setOpenBackdrop={setOpenBackdrop} openUpdate={openUpdate} setOpenUpdate={setOpenUpdate}/>

			<AppBarMenu view={"chat"}  setOpenBackdrop={setOpenBackdrop}/>

			<SwipeableDrawer
            anchor={"left"}
            open={openChats}
            onClose={() => {setOpenChats(false)}}
            onOpen={() => {setOpenChats(true)}}
			style={{maxWidth: "300px"}}
          >
			<>
			<Typography variant="h5" gutterBottom style={{color: "#656E71", marginLeft: "1rem"}}>Chats</Typography>
			<Divider />
            {chats.map((chat) => <ChatCell chat={chat} />)}
			</>
          </SwipeableDrawer>

			<EditChatbotModal />
			
			<Grid
				container
				size={12}
				style={{
					height: `calc(100svh - ${appBarHeight}px)`,
					paddingBottom: `${isLargeScreen ? "20px" : "0px"}`,
					paddingTop: `${isLargeScreen ? "20px" : "0px"}`,
					backgroundColor: "#E0E6E9",
					alignContent: "end",
				}}
				justifyContent="center"
				alignItems="center"
				alignContent="center"
			>
				
				
				<Chat
                    fbUser={fbUser}					
					openEditChatbotModal={handleOpenEditChatbotModal}
					selectedChat={selectedChat}
					setOpenChats={setOpenChats}
					userIsPremium={userIsPremium}
					setOpenUpdate={setOpenUpdate}

				/>
			
				{showPreLaunch ? <PreLaunchBuy /> : null}
			</Grid>
			
		</Grid>
	);
}
