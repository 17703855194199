/**
 * Landing page
 * Use material ui
 * use firebase to signin use anonymous when a new user lands on the page
 * use firebase to save user data
 * use firebase to save user data
 */

import React, { useEffect, useState, useRef } from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Container,
	Grid,
	Paper,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Fab,
	Box,
	CardContent,
	CardActions,
	Card,
  Divider,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useAuthState } from "react-firebase-hooks/auth";
// import { auth } from '../firebase';
import { getAuth, signInAnonymously } from "firebase/auth"; // Add this import
// import { app } from '../Firebase'; // Import the app instance
import { auth, db } from "../Firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import Chat from "../Components/Chat";
import heroImage from "../Assets/hero-image.png";
import StarIcon from "@mui/icons-material/Star";
import BonusIcon from "../Assets/bonus.png";
import SpaceialOffer from "../Assets/special-offer.png";
import AddInfo from "./AddInfo";
import AppBarMenu from "../Components/AppBarMenu";
import { useNavigate } from "react-router-dom";
const Landing: React.FC = () => {
	const [user] = useAuthState(auth);
	const [relation, setRelation] = useState<
		| "father"
		| "mother"
		| "brother"
		| "sister"
		| "friend"
		| "aunt"
		| "uncle"
		| "cousin"
		| "other"
		| string
	>("mother");
	const [name, setName] = useState<string>("");
	const [userLanguage, setUserLanguage] = useState<"Turkish" | "English">(
		"English"
	);
	const [refresh, setRefresh] = useState<boolean>(false);

	const [showPreLaunch, setShowPreLaunch] = useState<boolean>(false);
	const navigate = useNavigate();

	const nameInputRef = useRef<HTMLInputElement>(null); // Create a ref for the input

	const CssTextField = styled(TextField)({
		"& label.Mui-focused": {
			color: "#A0AAB4",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#B2BAC2",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#E0E3E7",
			},
			"&:hover fieldset": {
				borderColor: "#B2BAC2",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#6F7E8C",
			},
		},
	});

	useEffect(() => {
		if (!user) {
			console.log("signing in");
			// handleSignIn();
		} else {
			console.log("user is signed in");
			// auth.signOut().then(() => {
			//   console.log("signed out");
			// });
		}
	}, [user]);

	useEffect(() => {
		if (nameInputRef.current) {
			console.log("focusing in ref");
			nameInputRef.current.focus(); // Maintain focus on the input
		}
	}, [name]);

	const handleSignIn = async () => {
		const authInstance = getAuth(); // Get the auth instance

		await signInAnonymously(authInstance); // Use the imported method
	};

	function handleRelationChange(event: SelectChangeEvent) {
		setRelation(event.target.value as string);
		console.log("relation", event.target.value);
	}

	function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		setName(event.target.value);

		if (nameInputRef.current) {
			console.log("focusing");
			nameInputRef.current.focus(); // Maintain focus on the input
		}
	}

	function handleLanguageChange(event: SelectChangeEvent) {
		setUserLanguage(event.target.value as "Turkish" | "English");
		// console.log("language", event.target.value);
	}

	function LanguageSelector() {
		return (
			<FormControl fullWidth>
				<InputLabel id="language-select-label">Language</InputLabel>
				<Select
					labelId="language"
					id="language"
					value={userLanguage}
					label="Language"
					onChange={handleLanguageChange}
				>
					<MenuItem value={"Turkish"}>Turkish</MenuItem>
					<MenuItem value={"English"}>English</MenuItem>
				</Select>
			</FormControl>
		);
	}

	function handleRefresh() {
		console.log("refreshing");
		setRefresh(true);
		setTimeout(() => {
			setRefresh(false);
		}, 500);
	}

	function RefreshButton() {
		return (
			<Button
				variant="outlined"
				onClick={handleRefresh}
				style={{ marginBottom: "1rem", color: "white", borderColor: "white" }}
			>
				Refresh
			</Button>
		);
	}

	function HeroText() {
		return (
			<Grid container>
				<Grid container item xs={12}>
					<Grid item xs={12} md={6}>
						<Typography
							variant="h2"
							gutterBottom
							style={{ fontFamily: "Roboto, sans-serif", color: "#fdcb6e" }}
						>
							Capture your baby's precious memories
						</Typography>

						<Typography
							variant="body1"
							gutterBottom
							style={{
								fontSize: "1.2rem",
								color: "white",
								marginBottom: "20px",
							}}
						>
							Capture the magic of your child's memories and health milestones
							effortlessly with Mini Journal. Simply chat with an AI version of
							your child's future self to log their special moments.
						</Typography>
						<StartButton />
						<Typography
							variant="body1"
							gutterBottom
							style={{
								fontSize: "1.2rem",
								color: "white",
								marginBottom: "20px",
							}}
						>
							As parents, we cherish our children’s milestones, but tracking
							them can be tedious. Mini Journal transforms this process into an
							engaging experience, letting you relive precious memories as if
							you're talking with your grown up child.
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						{" "}
						<img
							src={heroImage}
							alt="Hero Image"
							style={{
								width: "100%",
								height: "auto",
								marginBottom: "20px",
								marginLeft: window.innerWidth > 960 ? "20px" : "0",
								borderRadius: "20px",
							}}
						/>
					</Grid>
				</Grid>

				<br />
				<Typography variant="h5" gutterBottom style={{ color: "#fd79a8" }}>
					Capture Every Precious Moment
				</Typography>
				<Typography
					variant="body1"
					gutterBottom
					style={{ fontSize: "1.2rem", color: "white", marginBottom: "20px" }}
				>
					Record the delightful new experiences your child has each day; first
					steps, first words, first ice cream taste, and every "first" along the
					way. Don’t let a single milestone slip away.
				</Typography>
				<br />
				<Typography variant="h5" gutterBottom style={{ color: "#fd79a8" }}>
					Document Health Events with Ease
				</Typography>
				<Typography
					variant="body1"
					gutterBottom
					style={{ fontSize: "1.2rem", color: "white", marginBottom: "20px" }}
				>
					Detail every medical moment effortlessly. From doctor visits to
					vaccinations. Mini Journal records all your entries and will soon be able to remind
					you of upcoming appointments and medication schedules, ensuring you
					never miss a beat in your child's health journey.
				</Typography>
				<br />
				<Typography variant="h5" gutterBottom style={{ color: "#fd79a8" }}>
					Share the Joy with Loved Ones (Coming Soon)
				</Typography>
				<Typography
					variant="body1"
					gutterBottom
					style={{ fontSize: "1.2rem", color: "white", marginBottom: "20px" }}
				>
					Invite grandparents and family members to join the fun. With Mini
					Journal, they can stay connected, relive precious moments, and even
					contribute their own cherished experiences with your child.
				</Typography>
				<br />

				<Typography variant="h5" gutterBottom style={{ color: "#fd79a8" }}>
					Try Mini Journal 👇
				</Typography>
				<Typography
					variant="body1"
					gutterBottom
					style={{ fontSize: "1.2rem", color: "white" }}
				>
					Start chatting about today’s fun moments or record a health event like a
					doctor visit. Mini Journal will request your consent before saving any
					memory, so you can review and cherish each moment. Explore and enjoy!
				</Typography>
			</Grid>
		);
	}

	const Iframe: React.FC<{ src: string; height: string; width: string }> = ({
		src,
		height,
		width,
	}) => {
		return (
			<div>
				<iframe src={src} height={height} width={width} />
			</div>
		);
	};

	function PreLaunchBuy() {
		return (
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				style={{ textAlign: "center", marginTop: "20px" }}
			>
				<Grid item>
					{/* <Card>
            <CardContent>  */}
					<Typography variant="h4" gutterBottom style={{ color: "#fd79a8" }}>
						Grab the Pre-Launch Special
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						style={{ fontSize: "1.2rem", color: "white" }}
					>
						Don't miss out on the pre-launch special
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						style={{ fontSize: "1.2rem", color: "white" }}
					>
						Get the "Life Time" deal that covers 2 Mini Journals for 2 children
					</Typography>
					<Grid
						container
						spacing={5}
						justifyContent="center"
						style={{ marginTop: "20px" }}
					>
						<Grid item xs={12} md={6}>
							<Card
								style={{
									borderRadius: "16px",
									position: "relative",
									overflow: "visible",
								}}
							>
								<img
									src={SpaceialOffer}
									alt="Special Offer"
									style={{
										position: "absolute",
										top: window.innerWidth > 960 ? "-40px" : "-10px",
										left: window.innerWidth > 960 ? "-60px" : "0px",
										width: window.innerWidth > 960 ? "100px" : "80px",
										height: "auto",
									}}
								/>
								<CardContent>
									<Grid container>
										<Grid
											item
											xs={12}
											justifyContent="center"
											style={{ textAlign: "center" }}
										>
											<Typography
												variant="h4"
												gutterBottom
												style={{ color: "#fdcb6e" }}
											>
												Pre Launch
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.5rem", color: "white" }}
											>
												$99
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												Lifetime
											</Typography>
											<img
												src={BonusIcon}
												alt="Bonus Icon"
												style={{
													width: "100px",
													height: "auto",
													borderRadius: "20px",
												}}
											/>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												2 Mini Journal
												<br />
												for
												<br />2 Children
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<CardActions>
									{" "}
									<Grid container justifyContent="center">
										<Grid item xs={10}>
											<Button
												variant="outlined"
												style={{
													color: "white",
													borderColor: "#FC5A5A",
													backgroundColor: "#FC5A5A",
													width: "100%",
												}}
												onClick={() =>
													window.open(
														"https://buy.stripe.com/6oE8y3aI3aRufNm144",
														"_blank",
														"width=1000,height=900"
													)
												}
											>
												Buy Now!
											</Button>
										</Grid>
									</Grid>
								</CardActions>
							</Card>
						</Grid>

						{/* After Launch */}
						<Grid item xs={12} md={6}>
							<Card sx={{ height: "100%" }} style={{ borderRadius: "16px" }}>
								<CardContent>
									<Grid container>
										<Grid
											item
											xs={12}
											justifyContent="center"
											style={{ textAlign: "center" }}
										>
											<Typography
												variant="h4"
												gutterBottom
												style={{ color: "#fdcb6e" }}
											>
												After Launch
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.5rem", color: "white" }}
											>
												$99
											</Typography>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												Per Year
											</Typography>
											{/* <img src={BonusIcon} alt="Bonus Icon" style={{ width: '100px', height: 'auto', marginBottom: '20px' , borderRadius: '20px'}} /> */}

											<div style={{ height: "100px", width: "100px" }}></div>
											<Typography
												variant="body1"
												gutterBottom
												style={{ fontSize: "1.7rem", color: "white" }}
											>
												1 Mini Journal
												<br />
												for
												<br />1 Child
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<CardActions>
									{" "}
									<Grid container justifyContent="center">
										<Grid item xs={10}>
											<Button
												variant="outlined"
												style={{
													color: "#FC5A5A",
													borderColor: "#FC5A5A",
													width: "100%",
												}}
												onClick={() =>
													window.open(
														"https://forms.gle/bw8yR5N25ypowcdj7",
														"_blank",
														"width=1000,height=900"
													)
												}
											>
												Notify Me
											</Button>
										</Grid>
									</Grid>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
					{/* </CardContent>
            </Card> */}

					{/* </Card> */}
				</Grid>
			</Grid>
		);
	}

	function FloatingPreLaunchButton() {
		return (
			<Box
				sx={{ "& > :not(style)": { m: 1 } }}
				style={{
					margin: 0,
					top: "auto",
					right: 20,
					bottom: 20,
					left: "auto",
					position: "fixed",
					zIndex: 1000,
				}}
			>
				<Fab
					variant="extended"
					style={{ backgroundColor: "#FC5A5A", color: "white" }}
					onClick={() =>
						window.open(
							"https://buy.stripe.com/6oE8y3aI3aRufNm144",
							"_blank",
							"width=1000,height=900"
						)
					}
				>
					<StarIcon sx={{ mr: 1 }} />
					Get Pre-Launch Special
				</Fab>
			</Box>
		);
	}

	function FloatingNotifyButton() {
		return (
			<Box
				sx={{ "& > :not(style)": { m: 1 } }}
				style={{
					margin: 0,
					top: "auto",
					right: 20,
					bottom: 20,
					left: "auto",
					position: "fixed",
					zIndex: 1000,
				}}
			>
				<Fab
					variant="extended"
					style={{ backgroundColor: "#FC5A5A", color: "white" }}
					onClick={() =>
						window.open(
							"https://forms.gle/bw8yR5N25ypowcdj7",
							"_blank",
							"width=1000,height=900"
						)
					}
				>
					<StarIcon sx={{ mr: 1 }} />
					Notify Me!
				</Fab>
			</Box>
		);
	}

	function FeedbackForm() {
		const [userName, setUserName] = useState<string>("");
		const [userEmail, setUserEmail] = useState<string>("");
		const [userReview, setUserReview] = useState<string>("");

		function handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
			setUserName(event.target.value);
		}

		function handleUserEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
			setUserEmail(event.target.value);
		}

		function handleUserReviewChange(
			event: React.ChangeEvent<HTMLInputElement>
		) {
			setUserReview(event.target.value);
		}

		function handleSubmit() {
			if (userName === "" && userEmail === "") {
				alert("Please enter your name and email");
				return;
			}

			console.log("submitting");
			console.log("userName", userName);
			console.log("userEmail", userEmail);
			console.log("userReview", userReview);

			saveFeedback();
		}

		async function saveFeedback() {
			//save to firebase
			const feedback = {
				name: userName,
				email: userEmail,
				review: userReview,
				date: Timestamp.fromDate(new Date()),
			};

			try {
				const docRef = await addDoc(collection(db, "feedback"), feedback);
				console.log("Document written with ID: ", docRef.id);
				setUserName("");
				setUserEmail("");
				setUserReview("");

				alert("Thank you for your feedback! We will get back to you soon.");
			} catch (e) {
				console.error("Error adding document: ", e);
				alert(`Error adding document: ${e}`);
			}
		}

		return (
			<Grid
				container
				spacing={3}
				justifyContent="center"
				alignItems="center"
				style={{ textAlign: "center", marginTop: "20px" }}
			>
				<Grid item>
					<Typography variant="h4" gutterBottom style={{ color: "#fd79a8" }}>
						Let’s Hear Your Thoughts! 🎉
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						style={{ fontSize: "1.2rem", color: "white" }}
					>
						Hey there, awesome human! 🤗 We’re building something exciting and
						we’d love your input. Got a brilliant idea, a quirky suggestion, or
						just wanna say hi? Fill out this form and let’s chat!
					</Typography>
					{/* <Typography
						variant="body1"
						gutterBottom
						style={{ fontSize: "1.2rem", color: "white" }}
					>
						Don’t want to share feedback right now? No worries! Just leave the
						feedback field blank and we’ll notify you when our app is ready. We
						can’t wait to hear from you!
					</Typography> */}
				</Grid>

				<Grid item xs={12} md={6}>
					<CssTextField
						key="nameTextField"
						value={userName}
						sx={{ borderColor: "white" }}
						color="primary"
						fullWidth
						id="name"
						label="Your Name"
						variant="outlined"
						onChange={handleUserNameChange}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<CssTextField
						key="emailTextField"
						value={userEmail}
						sx={{ borderColor: "white" }}
						type="email"
						color="primary"
						fullWidth
						id="name"
						label="Your Email"
						variant="outlined"
						onChange={handleUserEmailChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<CssTextField
						key="feedbackTextField"
						value={userReview}
						multiline
						rows={4}
						sx={{ borderColor: "white" }}
						color="primary"
						fullWidth
						id="name"
						label="Your Feedback"
						variant="outlined"
						onChange={handleUserReviewChange}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Button
						variant="outlined"
						style={{
							color: "white",
							borderColor: "#FC5A5A",
							backgroundColor: "#FC5A5A",
							width: "100%",
						}}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		);
	}

	function StartButton() {
		return (
			<Grid
				container
				justifyContent="center"
				style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
			>
				<Button
					variant="outlined"
					style={{
						color: "white",
						borderColor: "#FC5A5A",
						backgroundColor: "#FC5A5A",
						width: "100%",
						maxWidth: "300px",
						height: "50px",
					}}
					onClick={() => navigate("/signin")}
				>
					Start Chatting
				</Button>
			</Grid>
		);
	}

	function DemoChatView() {
    return(
      
      <>
      <Grid
					container
					style={{
						marginBottom: "20px",
						marginTop: "20px",
						justifyContent: "space-between",
						alignContent: "center",
					}}
					spacing={3}
				>
					{/* <Grid item xs={12} md={3}>
                <CssTextField key="nameTextField" sx={{borderColor: 'white'}} color="primary" fullWidth id="name" label="Your Child's Name" variant="outlined" onChange={handleNameChange} ref={nameInputRef} /></Grid> */}

					<AddInfo name={name} setName={setName} />

					<Grid item xs={12} md={3}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
								Your Relation
							</InputLabel>
							<Select
								labelId="relation"
								id="relation"
								value={relation}
								label="Your Relation"
								onChange={handleRelationChange}
								defaultValue={"mother"}
							>
								<MenuItem value={"father"}>Father</MenuItem>
								<MenuItem value={"mother"}>Mother</MenuItem>
								{/* <MenuItem value={30}>Thirty</MenuItem> */}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={3}>
						<LanguageSelector />
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						style={{ display: "flex", alignItems: "center" }}
					>
						<RefreshButton />
					</Grid>
				</Grid>
        <Grid item xs={12}>
						{/* <Grid item xs={12} md={6}> */}
						<Paper sx={{ p: 2, height: "400px", marginBottom: "50px" }}>
							{/* <Chat
								name={name}
								relation={relation}
								userLanguage={userLanguage}
								refresh={refresh}
							/> */}
						</Paper>
					</Grid>
      </>
    )
  }

	return (
		<>
			{/* <AppBar position="static" elevation={0}> 
        <Toolbar style={{backgroundColor: '#FC5A5A', color: 'white'}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{fontFamily: 'Montserrat, sans-serif'}}>
            Mini Journal AI
          </Typography>
        </Toolbar>
      </AppBar> */}

			<AppBarMenu />

			<Container maxWidth="md" sx={{ mt: 4 }}>
				<HeroText />

				{showPreLaunch ? <FloatingPreLaunchButton /> : null}

				<br />
        <StartButton />

				<Grid container spacing={3} style={{ marginBottom: "100px" }}>
					

					{showPreLaunch ? <PreLaunchBuy /> : null}

          <Divider style={{marginTop: '50px', marginBottom: '20px', borderColor: '#E0E6E9', borderWidth: '1px', width: '100%'}} />

					<FeedbackForm />

					<Grid container justifyContent="center" style={{ marginTop: "40px" }}>
						<Grid
							item
							xs={12}
							md={6}
							justifyContent="center"
							style={{ textAlign: "center" }}
						>
							<Typography variant="h6" gutterBottom style={{ color: "white" }}>
								Built by{" "}
								<a
									href="https://x.com/azizakgul"
									style={{ color: "#fd79a8", textDecoration: "none" }}
								>
									azizakgul
								</a>{" "}
								with ❤️ for his daughter
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default Landing;
