import { Backdrop, CircularProgress } from "@mui/material";



function MyBackdrop({openBackdrop}:{openBackdrop:boolean}) {
    return (
        <Backdrop
            sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default MyBackdrop