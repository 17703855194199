import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button,SelectChangeEvent } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useState } from "react";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E3E7',
      },
      '&:hover fieldset': {
        borderColor: '#B2BAC2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6F7E8C',
      },
    },
  });



export default function AddInfo({name, setName} : {name: string, setName: React.Dispatch<React.SetStateAction<string>>}) {

    // const [name, setName] = useState<string>("");

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    
        // if (nameInputRef.current) {
        //   console.log("focusing");
        //   nameInputRef.current.focus(); // Maintain focus on the input
        // }
        
      }

    return (
        
        <Grid item xs={12} md={3}>
        <CssTextField key="nameTextField" sx={{borderColor: 'white'}} color="primary" fullWidth id="name" label="Your Child's Name" variant="outlined" onChange={handleNameChange}  /></Grid>
             
             
            
    )
}
