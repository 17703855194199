import { useState, useEffect } from "react";
import { auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";

export default function useCustomerStatus(){
    const [userMustUpdate, setUserMustUpdate] = useState(false)

    useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			
			if(!user || !user.metadata.creationTime) return

            const creationTime = new Date(user.metadata.creationTime);
            const today = new Date();
            const differenceInDays = Math.abs((today.valueOf() - creationTime.valueOf()) / (1000 * 60 * 60 * 24));
            // console.log("differenceInDays", differenceInDays)
            // console.log("its more than 3 days: ", differenceInDays > 3)

            if(differenceInDays > 3){
                setUserMustUpdate(true)
            }
			
		});
	}, []);

	return userMustUpdate
}