import { useEffect, useState } from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	Box,
	Button,
	IconButton,
	Menu,
	MenuItem,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { auth } from "../Firebase";
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, SwipeableDrawer } from "@mui/material";
import createLifetimeCheckoutSession  from "../Helpers/lifetimePayment";
import useCustomerStatus from "../Helpers/useCustomerStatus";

export default function AppBarMenu({ view, setOpenBackdrop }: { view?: string, setOpenBackdrop?: (open: boolean) => void }) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();
	const [user, setUser] = useState<User | null>(null);

	const userIsPremium = useCustomerStatus()

	const pages = userIsPremium ? ["Chat", "Memories"] : ["Chat", "Memories", "Upgrade"];
	const menuItems = ["Sign Out"]; //["Account", "Help", "Sign Out"];
	const smallMenuItems = userIsPremium ? ["Chat", "Memories", "Sign Out"] : ["Chat", "Memories", "Upgrade", "Sign Out"];
	const isLargeScreen = useMediaQuery("(min-width:800px)");

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			setUser(user);
			// if(!user) return
		});
	}, []);

	async function handleNavMenuButton(page: string) {
		console.log("menu button", page);

		if (view && view == "chat" && page == "Chat") return;
		if (view && view == "memories" && page == "Memories") return;

		if (page == "Chat") {
			navigate("/chat");
		}
		if (page == "Memories") {
			navigate("/memories");
		}
		
		if (page == "Lifetime") {
			console.log("lifetime session...")
			setOpenBackdrop && setOpenBackdrop(true)
			await createLifetimeCheckoutSession()
			setOpenBackdrop && setOpenBackdrop(false)
		}
	}

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
		// console.log("menu close", event.currentTarget.id);
		if (event.currentTarget.id == "Sign Out") {
			signOut(auth)
				.then(() => {
					console.log("signed out");
					navigate("/");
				})
				.catch((error) => {
					console.log("error signing out", error);
				});
		}

		if (view && view == "chat" && event.currentTarget.id == "Chat") return;
		if (view && view == "memories" && event.currentTarget.id == "Memories")
			return;

		if (event.currentTarget.id == "Chat") {
			navigate("/chat");
		}
		if (event.currentTarget.id == "Memories") {
			navigate("/memories");
		}

		setAnchorEl(null);
	};

	return (
		<AppBar position="sticky" elevation={0}>
			{/* <SwipeableDrawer
            anchor={"left"}
            open={true}
            onClose={() => {}}
            onOpen={() => {}}
          >
            "Hello"
          </SwipeableDrawer> */}
			<Toolbar style={{ backgroundColor: "#FC5A5A", color: "white" }}>
				<Typography
					variant="h6"
					component="div"
					sx={{ flexGrow: 1 }}
					style={{ fontFamily: "Montserrat, sans-serif" }}
				>
					mini journal
				</Typography>
				<Box
					justifyContent={"flex-end"}
					sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
				>
					{user ? (
						pages.map((page) => (
							<Button
								key={page}
								onClick={() => handleNavMenuButton(page)}
								sx={{ my: 2, color: "white", display: "block" }}
							>
								{page}
							</Button>
						))
					) : ( null
						// <Button
						// 	key={"signin"}
						// 	onClick={() => navigate("/signin")}
						// 	sx={{ my: 2, color: "white", display: "block" }}
						// >
						// 	Sign In
						// </Button>
					)}
				</Box>

				{user ? (
					<div>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorEl)}
							onClose={handleMenuClose}
						>
							{isLargeScreen
								? menuItems.map((item) => (
										<MenuItem onClick={handleMenuClose} id={item}>
											{item}
										</MenuItem>
									))
								: smallMenuItems.map((item) => (
										<MenuItem onClick={handleMenuClose} id={item}>
											{item}
										</MenuItem>
									))}
						</Menu>
					</div>
				) : 
                <Button
							key={"signin"}
							onClick={() => navigate("/signin")}
							sx={{ my: 2, color: "white", display: "block" }}
						>
							Sign In
						</Button>
            }
			</Toolbar>
		</AppBar>
	);
}
