import React, { useEffect, useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Typography, CircularProgress, Backdrop } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import { Chatbot, FbUser } from '../Helpers/Interface';
import { updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../Firebase';
/**
 * - Get the chatbot info with chatbot id from firebase
 * - Show fields according to the chatbot interface @Interface.ts
 * - Use material ui text fields for the fields
 * - Use material ui select for the gender
 * - Use material ui date picker for the birthdate
 * - Use material ui text field for the name
 * - Use material ui text field for the description
 * - Use material ui text field for the avatar
 * - Use material ui button for the save button
 * - Use material ui button for the close button
 * - Use material ui button for the delete button
 * - Display it
 * - Allow editing
 * - Save
 * - Close the modal
 */

export default function EditChatbot({user, setUser, closeModal}: {user?: FbUser, setUser?: React.Dispatch<React.SetStateAction<FbUser | undefined>>, closeModal?: () => void}) {
    const [name, setName] = useState('');
    // const [description, setDescription] = useState('');
    // const [avatar, setAvatar] = useState('');
    const [gender, setGender] = useState('');
    const [relation, setRelation] = useState('');
    const [language, setLanguage] = useState('english');
    const [birthdate, setBirthdate] = useState<Dayjs | null>(null);

    const [openBackdrop, setOpenBackdrop] = useState(false);

    // useEffect(() => {
    //     // Get user info from auth
    //     // Get user info from firestore
    //     // get chatbot data from firebase firestore on load 

    // }, [chatbotId]);

    

    useEffect(() => {
        if(user){
            setName(user.chatbot.name || "");
            setGender(user.chatbot.gender || "");
            setRelation(user.chatbot.relation || "");
            setBirthdate(user.chatbot.birthdate ? dayjs.unix(user.chatbot.birthdate.seconds) : null);
            setLanguage(user.chatbot.language || "english");
            return
        }

        
        
    }, [user]);

    const handleSave = async () => {
        // Save logic here
        if(!user){
            console.log("no user");
            closeModal && closeModal();
            return;
        }


        //show loader from mui
        setOpenBackdrop(true)

        try {
            console.log("saving chatbot");
            await saveChatbot(user);
            setOpenBackdrop(false)
            closeModal && closeModal();
        } catch (error) {
            console.log("error saving chatbot", error);
            setOpenBackdrop(false)
        }

        // closeModal && closeModal();
    };

    async function saveChatbot(user: FbUser){
        
        const userRef = doc(db, 'users', user.userId);

        let chatbot = {...user.chatbot};

        chatbot.name = name ?? "";
        if(gender && gender.trim() !== "") chatbot.gender = gender;
        if(birthdate) chatbot.birthdate = new Timestamp(birthdate.toDate().getTime() / 1000, 0);
        if(language && language.trim() !== "") chatbot.language = language;
        if(relation && relation.trim() !== "") chatbot.relation = relation;

        if(compareObjects(chatbot, user.chatbot)){
            console.log("no changes to chatbot");
            return true;
        } 

        try {
            console.log("userId: ", user.userId);
            console.log("saving chatbot to firestore", chatbot);
            await updateDoc(userRef, {chatbot: chatbot});

            setUser && setUser({...user, chatbot: chatbot});

            return true;
        } catch (error) {
            throw error;
        }

        
    }

    function compareObjects(obj1 : Chatbot, obj2 : Chatbot){
        return (
          JSON.stringify(Object.entries(obj1).sort()) ===
          JSON.stringify(Object.entries(obj2).sort())
        );
    };

    const handleClose = () => {
        closeModal && closeModal();
    };

    function handleDateChange(date: Dayjs | null) {
        if(!date) return
        console.log("birthdate: ", date?.toString());
        setBirthdate(date);
    }

    

    return (
        <Box >
             <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={openBackdrop}
                onClick={() => {setOpenBackdrop(false);}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"} style={{height: "100lvh", flexGrow: 1}} >
            <Grid style={{backgroundColor: "#303952", padding: "20px", borderRadius: "8px"}} size={{ xs: 12, md: 4 }} container spacing={3} direction="column" alignItems={"left"} >
                <Grid> <Button variant="outlined" color="secondary" onClick={handleClose}>
                Close
            </Button>
            </Grid>
                <Grid><Typography>Please enter the details of your chatbot</Typography></Grid>

                <Grid>
            <TextField
                label="Child's Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
            /></Grid>


            

                <FormControl fullWidth>
                <InputLabel>Your Relation</InputLabel>
                <Select
                    value={relation !== "" ? relation : "mother"}
                    onChange={(e) => setRelation(e.target.value)}
                >
                    <MenuItem value="mother">Mother</MenuItem>
                    <MenuItem value="father">Father</MenuItem>
                    {/* <MenuItem value="grandMother">GrandMother</MenuItem>
                    <MenuItem value="grandFather">GrandFather</MenuItem>
                    <MenuItem value="uncle">Uncle</MenuItem>
                    <MenuItem value="aunt">Aunt</MenuItem> */}
                    <MenuItem value="other">Other</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel>Language</InputLabel>
                <Select
                    value={language !== "" ? language : "english"}
                    onChange={(e) => setLanguage(e.target.value)}
                >
                    <MenuItem value="english">English</MenuItem>
                    <MenuItem value="turkish">Turkish</MenuItem>
                    <MenuItem value="spanish">Spanish</MenuItem>
                    <MenuItem value="french">French</MenuItem>
                    <MenuItem value="german">German</MenuItem>
                    <MenuItem value="italian">Italian</MenuItem>
                    <MenuItem value="danish">Danish</MenuItem>
                    {/* <MenuItem value="portuguese">Portuguese</MenuItem>
                    <MenuItem value="russian">Russian</MenuItem>
                    <MenuItem value="chinese">Chinese</MenuItem>
                    <MenuItem value="japanese">Japanese</MenuItem>
                    <MenuItem value="korean">Korean</MenuItem> */}
                    {/* <MenuItem value="other">Other</MenuItem> */}
                </Select>
            </FormControl>



            {/* <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                helperText="You can add a backstory for your chatbot"
            /> */}
            
            <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Birthdate"
                value={birthdate}
                onChange={handleDateChange}
                // renderInput={(params) => <TextField {...params} fullWidth />}
            />
    </LocalizationProvider>


           
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save
            </Button>

           </Grid>
           </Grid>
        </Box>
    );
}
