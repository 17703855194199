import { useState, useEffect } from "react";
import { auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";

export default function useCustomerStatus(){
    const [userIsPremium, setUserIsPremium] = useState(true)

    useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			
			if(!user) return

			user.getIdTokenResult()
			.then((idTokenResult) => {
				// console.log("idTokenResult claims: ", idTokenResult.claims)
				if(!idTokenResult.claims.status || idTokenResult.claims.status !== "premium"){
					setUserIsPremium(false)
					return
				} 
				if(idTokenResult.claims.status === "premium" && userIsPremium === false){
					setUserIsPremium(true)
					return
				}
			})
			.catch((error) => {
				console.log("error getting user status", error)
			})
		});
	}, []);

	return userIsPremium
}