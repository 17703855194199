import {db, auth} from "../Firebase"
import {addDoc, collection, onSnapshot, doc} from "firebase/firestore"


export default async function createLifetimeCheckoutSession() {
    if(!auth.currentUser) return

    const uid = auth.currentUser.uid

    const docRef = collection(db, "customers", uid, "checkout_sessions")

    let sessionId: string | null = null

    const testPriceId = "price_1QFC1JLTt7B5R75kt0gCxDvg"
    const productionPriceId = "price_1QFBCOLTt7B5R75kQoWfER22"

    try {
        const result = await addDoc(docRef, {
            mode: "payment",
            price: productionPriceId, // One-time price created in Stripe
            success_url: `${window.location.origin}/chat`,
            cancel_url: `${window.location.origin}/chat`,
        })

        sessionId = result.id
        console.log("Session ID: ", sessionId)

    } catch (error) {
        console.error("Error creating checkout session: ", error)
    }

    if(!sessionId) return
    const sessionRef = doc(db, "customers", uid, "checkout_sessions", sessionId)

    const unsub = onSnapshot(sessionRef, (snap) => {
        const { error, url } = snap.data() as {error: string | null, url: string | null};
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${JSON.stringify(error)}`);
        }
        if (url) {
            console.log("session url: ", url)
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
        }
      });

      return () => unsub()

}

//     const docRef = await db
//   .collection("customers")
//   .doc(currentUser.uid)
//   .collection("checkout_sessions")
//   .add({
//     mode: "payment",
//     price: "price_1GqIC8HYgolSBA35zoTTN2Zl", // One-time price created in Stripe
//     success_url: window.location.origin,
//     cancel_url: window.location.origin,
//   });