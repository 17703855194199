// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, EmailAuthProvider } from "firebase/auth";
import { getFunctions } from "firebase/functions"; // Added import for getFunctions
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDulP9afgAmxNubaW1Hr95LAtaUiqEW1bc",
  authDomain: "mini-journal-ai.firebaseapp.com",
  projectId: "mini-journal-ai",
  storageBucket: "mini-journal-ai.appspot.com",
  messagingSenderId: "1045100486117",
  appId: "1:1045100486117:web:4b126bc41bc411fcf4230c",
  measurementId: "G-FZGCD7QXQ8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const emailAuthProvider = EmailAuthProvider
export const functions = getFunctions(app);

// export { app }; // Added export for app
const analytics = getAnalytics(app);