import React, { useState, useRef, useEffect } from 'react';
import { ChatContainer, MessageList, Message, MessageInput, TypingIndicator, MainContainer, Sidebar, ExpansionPanel, MessageInputProps, InputToolbox, InfoButton, ConversationHeader, Avatar,  ArrowButton } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";  
import { functions } from '../Firebase'; // Import the functions instance
import './styles.css';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
// import {Avatar} from '@mui/material';
import { deepOrange, deepPurple, pink, grey } from '@mui/material/colors';
import { Chip, Typography, Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { ChatMessage, ChatRequest, ChatResponse, Bot, FbUser, Chatbot, ChatThread } from '../Helpers/Interface';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Chat: React.FC<{
	fbUser?: FbUser;
	openEditChatbotModal?: () => void;
	selectedChat?: ChatThread;
  setOpenChats: (open: boolean) => void;
  userIsPremium: boolean;
  setOpenUpdate: (open: boolean) => void;
}> = ({ fbUser, openEditChatbotModal, selectedChat, setOpenChats, userIsPremium, setOpenUpdate }) => {
	// const [bot, setBot] = useState<Bot | undefined>(undefined);
	const [isTyping, setIsTyping] = useState(false);
	// const [memories, setMemories] = useState<{title: string, health_event: boolean}[]>([]);
	const threadId = useRef<string | null>(null);
	// const [childName, setChildName] = useState<string>(fbUser?.chatbot?.name || "");
	// const [childRelation, setChildRelation] = useState<string>(fbUser?.chatbot?.relation || "other");
	// const [language, setLanguage] = useState<string>(fbUser?.chatbot?.language || "english");

	const isLargeScreen = useMediaQuery("(min-width:800px)");

	const inputRef = useRef<HTMLInputElement>(null);

	const [messages, setMessages] = useState<ChatMessage[]>([
		getIntroMessage(fbUser?.chatbot),
	]);
	const typingMessage =
		fbUser && fbUser.chatbot.name && fbUser.chatbot.name !== ""
			? `${fbUser.chatbot.name} is typing...`
			: "typing...";

	useEffect(() => {
		console.log("chat view fbUser", fbUser);

		if (!fbUser) return;

    if(!selectedChat){
      setMessages([getIntroMessage(fbUser.chatbot)]);
      return;
    }

    
	}, [fbUser]);

  useEffect(() => {
    console.log("selectedChat", selectedChat);

    if (!fbUser) return;

    if(!selectedChat){
      setMessages([getIntroMessage(fbUser.chatbot)]);
      return;
    }

    let allMessages: ChatMessage[] = [];

    for (const message of selectedChat.messages) {
      allMessages.push({
        message: message.content,
        sentTime: message.created.toDate().toLocaleTimeString(),
        sender: message.role,
        timestamp: message.created,
      })
    }

    for (const memory of selectedChat.memories) {
      allMessages.push({
        message: memory.content,
        sentTime: memory.created.toDate().toLocaleTimeString(),
        sender: "assistant",
        memory: true,
        health_event: memory.type === "healthEvent",
        timestamp: memory.created,
      })
    }

    allMessages.sort((a, b) => (a.timestamp?.seconds ?? 0) - (b.timestamp?.seconds ?? 0));

    setMessages(allMessages);
    threadId.current = selectedChat.threadId;

  }, [selectedChat]);

	const handleSend = async (message: string) => {
		const newMessage: ChatMessage = {
			message,
			sentTime: new Date().toLocaleTimeString(),
			sender: "user",
		};
		setMessages([...messages, newMessage]);
		// Here you would typically send the message to your backend or AI service

		if (!fbUser?.chatbot) {
			//show a message to the user that the bot is not set
			alert("Bot is not set");
			return;
		}

		let chatRequest: ChatRequest = {
			message,
		};

		if (threadId.current) chatRequest.threadId = threadId.current;

		setIsTyping(true);
		if (inputRef.current) inputRef.current.focus();

		let result: ChatResponse;

		try {
			result = await sendMessageToAssistant(chatRequest);
			console.log("result", result);
			setIsTyping(false);
			if (!result) return;
		} catch (error) {
			console.log("Error getting response from assistant", error);
			alert("Error getting response from chatbot");
			setIsTyping(false);
			return;
		}

		let newMemory: ChatMessage | undefined;

		if (result.memory && result.health_event !== undefined) {
			console.log("Writing result.memory", result.memory);
			newMemory = {
				message: result.memory,
				sentTime: new Date().toLocaleTimeString(),
				sender: "assistant",
				memory: true,
				health_event: result.health_event,
			};
		}

		if (!threadId.current) threadId.current = result.threadId;

		const newResponse: ChatMessage = {
			message: result.message,
			sentTime: new Date().toLocaleTimeString(),
			sender: "assistant",
		};

		const allMessages = newMemory
			? [...messages, newMessage, newMemory, newResponse]
			: [...messages, newMessage, newResponse];

		setMessages(allMessages);
		setIsTyping(false);
	};

	async function sendMessageToAssistant(chatRequest: ChatRequest) {
		const chatWithAssistant = httpsCallable(functions, "chatWithAssistant");
		const result = await chatWithAssistant(chatRequest);

		return result.data as ChatResponse;
	}

	function handleRefresh() {
		console.log("Refreshing");
		setMessages([getIntroMessage(fbUser?.chatbot)]);
		threadId.current = null;
		// setMemories([]);
	}

	interface ExtentedInputProps extends MessageInputProps {
		as?: string | typeof MessageInput;
	}

	const ExtendedInput = (props: ExtentedInputProps) => {
		return (
			<div>
				<span>
					<MessageInput
						className="cs-message-input__content-editor-container"
						style={{ fontSize: "1em", flexDirection: "row" }}
						ref={inputRef}
						attachButton={false}
						placeholder="Type message here..."
						onSend={handleSend}
					/>
				</span>
			</div>
		);
	};

	function getIntroMessage(chatbot?: Chatbot) {
		if (!chatbot) {
			const introMessage: ChatMessage = {
				message: `Hi, any fun memories today? 😊`,
				sentTime: new Date().toLocaleTimeString(),
				sender: "assistant",
			};
			return introMessage;
		}

		//English default
		let relation =
			chatbot.relation === "mother"
				? " mom"
				: chatbot.relation === "father"
					? " dad"
					: "";
		let text = `Hi${relation}, any fun memories today? 😊`;

		if (chatbot.language === "turkish") {
			relation =
				chatbot.relation === "mother"
					? " anne"
					: chatbot.relation === "father"
						? " baba"
						: "";
			text = `Merhaba${relation}, bugün neler yaptım? 😊`;
		}

		if (chatbot.language === "danish") {
			relation =
				chatbot.relation === "mother"
					? " mor"
					: chatbot.relation === "father"
						? " far"
						: "";
			text = `Hej${relation}, hvad har du gjort i dag? 😊`;
		}

		if (chatbot.language === "spanish") {
			relation =
				chatbot.relation === "mother"
					? " madre"
					: chatbot.relation === "father"
						? " padre"
						: "";
			text = `Hola${relation}, ¿qué has hecho hoy? 😊`;
		}

		if (chatbot.language === "french") {
			relation =
				chatbot.relation === "mother"
					? " maman"
					: chatbot.relation === "father"
						? " papa"
						: "";
			text = `Bonjour${relation}, qu'est-ce que tu as fait aujourd'hui? 😊`;
		}

		if (chatbot.language === "german") {
			relation =
				chatbot.relation === "mother"
					? " mama"
					: chatbot.relation === "father"
						? " papa"
						: "";
			text = `Hallo${relation}, was hast du heute gemacht? 😊`;
		}

		if (chatbot.language === "italian") {
			relation =
				chatbot.relation === "mother"
					? " mamma"
					: chatbot.relation === "father"
						? " papà"
						: "";
			text = `Ciao${relation}, cosa hai fatto oggi? 😊`;
		}

		const introMessage: ChatMessage = {
			message: text,
			sentTime: new Date().toLocaleTimeString(),
			sender: "assistant",
		};

		return introMessage;
	}

	function MessageCell({
		message,
		index,
	}: {
		message: ChatMessage;
		index: number;
	}) {
		if (message.memory && message.health_event !== undefined) {
			return (
				<Message
					className={
						message.health_event === true
							? "health-chat-container"
							: "memory-chat-container"
					}
					key={index}
					model={{
						// message: message.message,
						type: "custom",
						sentTime: message.sentTime,
						sender: message.sender,
						direction: message.sender === "user" ? "outgoing" : "incoming",
						position: "normal",
						// style: { backgroundColor: message.memory ? '#f0f8ff' : 'transparent' } // Change background color for memory
					}}
				>
					<Message.CustomContent>
						<strong>
							{message.health_event === true
								? "💊 Health Event Saved"
								: "💭 Memory Saved"}{" "}
						</strong>
						<br />
						{message.message}
					</Message.CustomContent>
				</Message>
			);
		}

		return (
			<Message
      // className={"memory-chat-container"}
    
      key={index}
				model={{
					message: message.message,
					sentTime: message.sentTime,
					sender: message.sender,
					direction: message.sender === "user" ? "outgoing" : "incoming",
					position: "normal",
          
				}}
			/>
		);
	}

  function handleOpenChats(){
    setOpenChats(true);
  }

  function UpgradeChipView(){

	return(
		<Message className="upgrade-chat-container" model={{
			direction: "incoming",
			type: "custom",
			position: "normal"
		  }}>
			<Message.CustomContent>
			<Chip style={{backgroundColor: "#FC5A5A", padding: "20px"}} label="👉 Upgrade now to keep a lifetime of memories" onClick={() => setOpenUpdate(true)}/>
			  {/* <strong>This is strong text</strong><br/>
			  Message content is provided as <span style={{color: "red"}}> custom elements</span> from
			  child <strong>Message.CustomContent</strong> element */}
			</Message.CustomContent>
		  </Message>
	)


    // return <Chip label="Upgrade now to keep a lifetime of memories" onClick={() => setOpenUpdate(true)}/>
  }

	return (
		<Grid size={isLargeScreen ? 6 : 12} style={{ height: "100%" }}>
			<MainContainer style={{ borderRadius: "0.5rem" }}>
				<ChatContainer>
					<ConversationHeader>
        
						<ConversationHeader.Content
							// info="Active 10 mins ago"
							userName="mini journal"
							// style={{display: "flex", flexDirection: "row", alignItems: "center"}}
						>
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
							>
								<Grid size={1}>
									<MenuIcon  sx={{ color: grey[400] }} onClick={handleOpenChats}/>
								</Grid>
								<Grid size={11} container justifyContent="center" alignItems="center">
									<Grid>
										<Button
											onClick={openEditChatbotModal}
											style={{ fontSize: "1.5rem", fontWeight: "600" , textTransform: 'capitalize'}}
											endIcon={<KeyboardArrowDownIcon/>}
										>
											{fbUser?.chatbot.name ?? "mini journal"} {"  "}
											
										</Button>
										{/* <Typography>Deniz</Typography> */}
									</Grid>
								</Grid>
							</Grid>
						</ConversationHeader.Content>

						<ConversationHeader.Actions>
							<CachedIcon sx={{ color: grey[400] }} onClick={handleRefresh} />
						</ConversationHeader.Actions>
					</ConversationHeader>

					<MessageList
						typingIndicator={
							isTyping && <TypingIndicator content={typingMessage} />
						}
					>
						<>
						{!userIsPremium && <UpgradeChipView/>}						
						{messages.map((m, i) => (
							<MessageCell key={i} message={m} index={i} />
						))}
						</>
					</MessageList>
					<ExtendedInput as={MessageInput} />
				</ChatContainer>
			</MainContainer>
		</Grid>
	);
};

export default Chat;
