import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Card, CardActions, CardContent, IconButton } from '@mui/material';
import Grid from "@mui/material/Grid2"
import createLifetimeCheckoutSession from '../Helpers/lifetimePayment';
import CloseIcon from '@mui/icons-material/Close';


// export interface SimpleDialogProps {
//   setOpenBackdrop: (open: boolean) => void;
//   open: boolean;
//   selectedValue: string;
//   onClose: (value: string) => void;
// }

// function SimpleDialog(props: SimpleDialogProps) {
//   const { setOpenBackdrop, selectedValue, open, onClose } = props;

//   const handleClose = () => {
//     onClose(selectedValue);
//   };

//   const handleListItemClick = (value: string) => {
//     onClose(value);
//   };

//   async function handleUpgrade() {
//     console.log("upgrade")
//     setOpenBackdrop && setOpenBackdrop(true)
// 			await createLifetimeCheckoutSession()
// 			setOpenBackdrop && setOpenBackdrop(false)
//   }


//   return (
//     <Dialog onClose={handleClose} open={open}>
//       <DialogTitle>Upgrade now to get a lifetime of memories</DialogTitle>
//       <Card>
//         <CardContent>
//           <Typography variant="h5" style={{textAlign: "center", textDecoration: "line-through"}}>$99</Typography>
//           <Typography variant="h6" style={{textAlign: "center"}}>$49</Typography>
//           <Typography variant="body1" style={{textAlign: "center"}}>Buy once, get memories forever. No subscriptions, no hidden fees.</Typography>
//         </CardContent>
//         <CardActions>
//           <Button variant="contained" color="success" onClick={handleUpgrade}>Upgrade</Button>
//         </CardActions>
//       </Card>
//     </Dialog>
//   );
// }

export default function UpdateView({setOpenBackdrop, openUpdate, setOpenUpdate}: {setOpenBackdrop: (open: boolean) => void, openUpdate: boolean, setOpenUpdate: (open: boolean) => void}) {

//   const [open, setOpen] = React.useState(openUpdate);
  
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

  const handleClose = () => {
    setOpenUpdate(false);
  };

  async function handleUpgrade() {
    console.log("upgrade")
    setOpenBackdrop(true)
    setOpenUpdate(false)
    await createLifetimeCheckoutSession()
    setOpenBackdrop(false)
    
  }

  return (
		<Dialog
			onClose={handleClose}
			open={openUpdate}
			PaperProps={{
				style: {
					backgroundColor: "grey",
					boxShadow: "none",
				},
			}}
		>
			{/* <DialogTitle style={{textAlign: "center", color: "#3E4348",
		fontFamily: "Roboto, sans-serif",
		fontWeight: 500,
		fontSize: "40px",}}>Upgrade now to get a lifetime of memories</DialogTitle> */}
			<Card style={{ backgroundColor: "white", paddingTop: "40px", paddingBottom: "40px", paddingLeft: "10px", paddingRight: "10px" }}>
                <IconButton aria-label="close" onClick={handleClose} style={{position: "absolute", top: "10px", left: "10px"}}> <CloseIcon sx={{color: "#AAAFB9"}}/></IconButton>

               
               
				<CardContent>
					<Grid container justifyContent="center" alignItems="center" spacing={2}>
						<Grid>
							<Typography
								variant="h4"
								style={{
									textAlign: "center",
									color: "#656E71",
									fontFamily: "Roboto, sans-serif",
									fontWeight: 500,
									fontSize: "40px",
								}}
							>
								Upgrade now to keep a lifetime of precious memories
							</Typography>
						</Grid>

                        {/* <Card variant="outlined" raised sx={{ backgroundColor: "white", padding: "40px", borderRadius: "8px", borderColor: "#E0E6E9" }}>

                        <Grid size={12}>
							<Typography
								variant="h4"
								style={{
									textAlign: "center",
									fontFamily: "Roboto, sans-serif",
									fontWeight: 600,
									fontSize: "40px",
									color: "#303952",
								}}
							>
								Lifetime Price
							</Typography>
						</Grid>

						<Grid size={12}>
							<Typography
								variant="h6"
								style={{
									textAlign: "center",
									textDecoration: "line-through",
									color: "#656E71",
								}}
							>
								$99
							</Typography>
						</Grid>

						<Grid size={12}>
							<Typography
								variant="h4"
								style={{
									textAlign: "center",
									fontFamily: "Roboto, sans-serif",
									fontWeight: 600,
									fontSize: "50px",
									color: "#546de5",
								}}
							>
								$49
							</Typography>
						</Grid>

                        </Card> */}


                        



                        <Card variant="outlined" raised sx={{ backgroundColor: "#546de5", padding: "40px", borderRadius: "8px", marginTop: "20px", marginBottom: "20px" }}>

<Grid size={12}>
    <Typography
        variant="h4"
        style={{
            textAlign: "center",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 600,
            fontSize: "40px",
            color: "white",
        }}
    >
        Lifetime Price
    </Typography>
</Grid>

<Grid size={12}>
    <Typography
        variant="h6"
        style={{
            textAlign: "center",
            textDecoration: "line-through",
            color: "white",
        }}
    >
        $99
    </Typography>
</Grid>

<Grid size={12}>
    <Typography
        variant="h4"
        style={{
            textAlign: "center",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 600,
            fontSize: "50px",
            color: "#f7d794",
        }}
    >
        $49
    </Typography>
</Grid>

</Card>

						<Grid size={12}>
							<Typography
								variant="body1"
								style={{ textAlign: "center", color: "#3E4348" }}
							>
								Buy once and keep your baby's lifetime of memories.
							</Typography>
						</Grid>

                        <Grid size={12}>
							<Typography
								variant="body1"
								style={{ textAlign: "center", color: "#3E4348" }}
							>
								No subscriptions, no hidden
								fees.
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions style={{justifyContent: "center"}}>
					<Button
						variant="contained"
						color="success"
						onClick={handleUpgrade}
						style={{ textAlign: "center", color: "white", backgroundColor: "#FC5A5A", width: "180px", height: "50px" }}
					>
						Upgrade
					</Button>
				</CardActions>
			</Card>
		</Dialog>
	);
}
