import React, { useEffect, useState } from 'react';
import { auth, db } from '../Firebase'; // Import your Firebase configuration
import { collection, doc, getDoc, query, where, getDocs  } from "firebase/firestore"; 
import { List, ListItem, ListItemText, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Avatar, ListItemAvatar, Typography, useMediaQuery, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ChatThread, Memory } from '../Helpers/Interface';
import { useParams } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import AppBarMenu from '../Components/AppBarMenu';
import MyBackdrop from '../Components/MyBackdrop';
import healthIcon from '../Assets/health-icon.png'
import memoryIcon from '../Assets/memory-icon.png'
import useAppBarHeight from "../Helpers/useAppBarHeight";

const Memories = () => {
    const { userId } = useParams();
    const [memories, setMemories] = useState<Memory[]>([]);
    const [open, setOpen] = useState(false);
    const [currentMemory, setCurrentMemory] = useState({ id: '', text: '' });
    const [openBackdrop, setOpenBackdrop] = useState(false)

    const appBarHeight = useAppBarHeight();
    const isLargeScreen = useMediaQuery("(min-width:800px)");
    
    useEffect(() => {

        if(userId){
            console.log("userId in memories: ",userId)
            getMemories(userId).then(m=>{
                m && setMemories(m)
                console.log("memories: ",m)
            }).catch(e=>console.error(e))

            return
        }

        onAuthStateChanged(auth, (user) => {
            console.log("user in memories: ",user)
            if(!user) return
            getMemories(user.uid).then(m=>{
                m && setMemories(m)
                console.log("memories: ",m)
            }).catch(e=>console.error("error in memories: ",e))

        })

    }, []);

    async function getMemories(userId:string){
        const chatsRef = collection(db, "chats");
        const  memoriesQuery = query(chatsRef, where("userId", "==", userId), where("memories", "!=", []));

        try {
            const querySnapshot = await getDocs(memoriesQuery);
            if(querySnapshot.empty) return undefined

            let memories : Memory[] = []

            for (const doc of querySnapshot.docs) {
                const thread = doc.data() as ChatThread
                memories.push(...thread.memories)
            }

            memories.sort((a,b)=>a.created.seconds - b.created.seconds)

            return memories

        } catch (error) {
            throw error
        } 
    }

    // const handleDelete = async (id: string) => {
    //     await db.collection('memories').doc(id).delete();
    //     setMemories(memories.filter(memory => memory.id !== id));
    // };

    const handleEditClick = (memory: any) => {
        setCurrentMemory(memory);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentMemory({ id: '', text: '' });
    };

    // const handleEditSubmit = async () => {
    //     await db.collection('memories').doc(currentMemory.id).update({ text: currentMemory.text });
    //     setMemories(memories.map(memory => (memory.id === currentMemory.id ? currentMemory : memory)));
    //     handleClose();
    // };

    function MemoryCell({memory}:{memory:Memory}){
        
        const src = memory.type === "memory" ? memoryIcon : healthIcon


        return <ListItem>
             <ListItemAvatar>
          <Avatar alt="Memory" src={src} />
        </ListItemAvatar>
            <ListItemText style={{color:"#656E71"}} primary={memory.created.toDate().toLocaleDateString()} 
            
            secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: '#303952', display: 'inline' }}

                  >
                    {memory.content}
                  </Typography>
                  
                </>
            
            }
            />
            
        </ListItem>
    }

    return (

        <Grid
			container
			style={{
				height: "100svh",
				margin: 0,
				width: "100svw",
				maxWidth: "100vw",
				overflowX: "hidden",
			}}
            justifyContent={"center"}
		>
			<MyBackdrop openBackdrop={openBackdrop} />

			<AppBarMenu view={"memories"} />

            <Grid
				container
				size={12}
				style={{
					height: `calc(100svh - ${appBarHeight}px)`,
					paddingBottom: `${isLargeScreen ? "20px" : "0px"}`,
					paddingTop: `${isLargeScreen ? "20px" : "0px"}`,
					backgroundColor: "#E0E6E9",
					alignContent: "flex-start",
				}}
				justifyContent="center"
				alignItems="center"
				alignContent="center"
			>


            <Grid size={{xs:12, md:6}} alignItems="flex-start" sx={{backgroundColor:"white", borderRadius:"8px" }} > 
                {/* <Typography variant="h4">Memories</Typography> */}
                <List>
                {memories.map((memory, index) => (
                    <>
                   <MemoryCell memory={memory} />
                   <Divider variant="inset" component="li" />
                   </>
                ))}
            </List>

            </Grid>
            </Grid>

        
           

            {/* <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Memory</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Memory"
                        type="text"
                        fullWidth
                        value={currentMemory.text}
                        onChange={(e) => setCurrentMemory({ ...currentMemory, text: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog> */}
        
        </Grid>
    );
};

export default Memories;
