import { collection, doc, getDoc } from "firebase/firestore"; 
import { db } from "../Firebase";
import { FbUser } from "./Interface";


export const getFbUser = async (userId: string) => {
    const usersRef = collection(db, "users");
    const userDoc = doc(usersRef, userId);
    try {
        const user = await getDoc(userDoc);
        if(user.exists()){
            return user.data() as FbUser;   
        }else{
            return undefined;
        }
    } catch (error) {
        console.log("error getting fbuser", error);
        return undefined;
    }
    
}