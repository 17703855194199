import React from 'react';
import logo from './logo.svg';
import './App.css';
import Landing from './Views/Landing'; // Changed to default import
import ChatView from './Views/ChatView';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";

import { BrowserRouter } from "react-router-dom";
import EditChatbot from './Views/EditChatbot';
import Home from './Views/Home';
import Signin from './Views/Signin';
import Memories from './Views/Memories';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing/>,
  },
  {
    path: "chat",
    element: <ChatView/>,
  },
  {
    path: "edit",
    element: <EditChatbot/>,
  },
  {
    path: "home",
    element: <Home/>,
  },
  {
    path: "signin",
    element: <Signin/>,
  },
  {
    path: "memories",
    element: <Memories/>,
  },
  {
    path: "memories/:userId",
    element: <Memories/>,
  },
]);


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {/* <Landing/> */}
      <RouterProvider router={router} />


      {/* <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="chat" element={<ChatView />} />
      </Routes>
    </BrowserRouter> */}

    </ThemeProvider>
    
  )
}

export default App;
